import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import Header from '../components/Header/Header';
import PrivateRoute from '../components/PrivateRoute';
import { AppWrapper, RouteWrapper } from '../components/styledComponents';
import Login from './Login/Login';
import TenderDetails from './TenderDetails/TenderDetails';
import TenderList from './TenderList/TenderList';
import TruckDriverinfo from './TenderDetails/TruckDriverinfo';
import { refreshLogin } from './../store/actions/userActions';

import Location from './Location/Location';
import EditLocation from './Location/EditLocation';

import RateBook from './RateBook/RateBook';
import Dispatch from './Dispatch/Dispatch';
import Templates from './Templates/Templates';
import ShipperConsignee from './ShipperConsignee/ShipperConsignee';
import TruckDriver from './TruckDriver/TruckDriver';
import PickupDisposition from './Maintenance/PickupDisposition/PickupDisposition';
import OrderBy from './Maintenance/OrderBy/OrderBy';
import Orders from './Orders/Orders';
import ElasticDashBoard from './ElasticDashBoard/ElasticDashBoard'
// import Order from './Orders/Order';
import Order from './Orders/Order';
import Trace from './Trace/Trace';
import EdiOrder from './EdiOrder/EdiOrder';

const routes = [
    {
        component: () => <Redirect to='/login' />,
        path: '/',
        exact: true,
        isPrivate: false,
    },
    // TO DO: create every scene for the router
    {
        component: Login,
        path: '/login',
        exact: true,
        isPrivate: false,
    },
    {
        component: ElasticDashBoard,
        path: '/orders',
        exact: true,
        isPrivate: true,
    },
    {
        component: TenderList,
        path: '/tenderList',
        exact: true,
        isPrivate: true,
    },
    {
        component: TenderDetails,
        path: '/details/:controlNum/:locationNum',
        exact: true,
        isPrivate: true,
    },

    {
        component: Location,
        path: '/location',
        exact: true,
        isPrivate: true,
    },

    {
        component: EditLocation,
        path: '/addlocation',
        exact: true,
        isPrivate: true,
    },


    {
        component: EditLocation,
        path: '/addlocation/:email',
        exact: true,
        isPrivate: true,
    },

    {
        component: TruckDriverinfo,
        path: '/tenderList/details/:driverId/:locNum/:truckId',
        exact: true,
        isPrivate: true,
    },
    {
        component: Order,
        path: '/orders/:locationNumber/:controlNumber/:orderType?',
        exact: true,
        isPrivate: true,
    },
    {
        component: RateBook,
        path: '/ratebook',
        exact: true,
        isPrivate: false,
    },
    {
        component: ElasticDashBoard,
        path: '/elasticdashboard',
        exact: true,
        isPrivate: false,
    },
    {
        component: Dispatch,
        path: '/dispatch',
        exact: true,
        isPrivate: false,
    },
    {
        component: Templates,
        path: '/templates',
        exact: true,
        isPrivate: false,
    },
    {
        component: ShipperConsignee,
        path: '/shipperconsignee',
        exact: true,
        isPrivate: false,
    },
    {
        component: Trace,
        path: '/trace',
        exact: true,
        isPrivate: false,
    },
    {
        component: TruckDriver,
        path: '/truckdriver',
        exact: true,
        isPrivate: false,
    },
    {
        component: PickupDisposition,
        path: '/equip/pick-up/dispo',
        exact: true,
        isPrivate: false,
    },
    {
        component: OrderBy,
        path: '/order-by',
        exact: true,
        isPrivate: false,
    },
    {
        component: EdiOrder,
        path: '/edi-orders',
        exact: true,
        isPrivate: false,
    },
    // {
    //     component: ForgotPassword,
    //     path: '/forgot-password',
    //     exact: true,
    //     isPrivate: false,
    // },
    // {
    //     component: ResetPassword,
    //     path: '/reset-password/:token',
    //     exact: true,
    //     isPrivate: false,
    // },
];

export const MainRouter = () => {
    let [isScreen, setIsScreen] = useState()
    const loggedIn = useSelector(({ userReducer }) => userReducer.loggedIn);
    const dispatch = useDispatch();
    useEffect(() => {
        if (loggedIn) dispatch(refreshLogin());
    }, [loggedIn, dispatch]);

    const screenStatus = useSelector(({ loadsReducer }) => {
        return loadsReducer.screenStatus
    });
    useEffect(() => {
        setIsScreen(screenStatus)
    }, [screenStatus]);

    const containerRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;

        const resizeObserver = new ResizeObserver((entries) => {
            const { width } = entries[0].contentRect;
            console.log('Container width changed:', width);
            window.containerScreenWidth = width;
        });

        resizeObserver.observe(container);

        return () => {
            resizeObserver.unobserve(container);
        };
    }, []);

    // Handle document title
    const location = useLocation();
    useEffect(() => {
        let title = location?.pathname.split("/")
        if (title.includes('orders') && typeof title[3] !== 'undefined') {
            document.title = 'Eagle System' + capitalize(title[1]) + ' #' + title[3];
        } else {
            document.title = 'Eagle System' + capitalize(title[1]);
        }

    }, [location]);

    const capitalize = (str) => {
        let newStr = (str !== '') ? ' - ' + str.charAt(0).toUpperCase() + str.slice(1) : '';
        return newStr;
    }

    return (
        <AppWrapper>
            <Switch>
                <RouteWrapper isScreenClicked={isScreen} ref={containerRef} >
                    {loggedIn && <Header />}
                    {routes.map((route, index) => {
                        let tempRoute = Object.assign({}, route);
                        return route.isPrivate
                            ? <PrivateRoute key={`Route-${index}`} {...tempRoute} />
                            : <Route key={`Route-${index}`} {...tempRoute} />;
                    })}
                </RouteWrapper>
            </Switch>
        </AppWrapper>
    );
};