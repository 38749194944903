import {truckdriverTypes } from "../types/truckdriverTypes"
import { Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk'
import { GET, POST, PUT, DELETE } from '../../utils/axios';
import moment from 'moment';
import { maptoTruckDriverTable } from "../../scenes/TruckDriver/component/commonFn";



export const getTruckDriverData = ( locationNumber: number) => {
    return async (dispatch: Dispatch) => {
        const res = await truckDriverApiReq( locationNumber);
        dispatch(setTruckDriver(res));
        return res;
    };
}

const setTruckDriver = (apiData: any) => {
    return {
        type: truckdriverTypes.TRUCKDRIVER_DATA,
        payload: apiData,
    };
};

export const truckDriverApiReq = async (locationNumber: number) => {
    try {
        
        let res = await GET(`/trucks/driver/maintenance/list/${locationNumber}`)
      
        if (res) {
        console.log("res.data",res)
            let data = maptoTruckDriverTable(res);
            console.log("data",data)
          
            return data;
        }
        return [];
    } catch (err) {
        return [];
    }
}


export const editTruckDriverVendor = (locationNumber: number,postData:any) => {
    return async (dispatch: Dispatch) => {
         const res = await editTruckDriverReq(locationNumber,postData);
        // dispatch(setShippers(res));
        return res;
    }
}

export const editTruckDriverReq = async (locationNumber: number, postData: any) => {
    try {
       
          let  res = await PUT(`/trucks/driver/maintenance/${locationNumber}`, postData); // Assuming you have a PUT function
      
        return res;
    } catch (err) {
        // Handle errors appropriately
        console.error("Error:", err);
        return [];
    }
}

export const getSingleTruckDriverData = ( locationNumber: number,truckNumber: number,driverNumber: number) => {
    return async (dispatch: Dispatch) => {
        const res = await singleTruckDriverApiReq( locationNumber,truckNumber,driverNumber);
        dispatch(setTruckDriver(res));
        return res;
    };
}

export const singleTruckDriverApiReq = async (locationNumber: number,truckNumber: number,driverNumber: number) => {
    try {
        
        let res = await GET(`/trucks/driver/maintenance/single/${truckNumber}/${driverNumber}`)
      
        if (res.data) {
        console.log("res.data",res)
            let data = maptoTruckDriverTable([res.data]);
            console.log("data",data)
          
            return data;
        }
        return [];
    } catch (err) {
        return [];
    }
}