import { useEffect, useState } from "react";
import { TextField, Autocomplete } from "@mui/material";
import { Select, MenuItem } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";

export default function ShipperForm({ shipperFormRef ,editShipper}) {
  const [formData, setFormData] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setFormData(shipperFormRef?.current);
  }, [shipperFormRef]);
  function correctSpaceFormat(value) {
    // Replace any non-standard whitespace characters with regular spaces
    return value.replace(/\s+/g, ' ');
}

  const handleChangeData = (event) => {
    const { name, value } = event.target;
    let newValue = correctSpaceFormat(value.toUpperCase()); // Convert value to uppercase

    // Validate the length based on the field name
    const maxCharacterLength = {
      code: 7,
      shipperConsName: 25,
      address: 25,
      city: 25,
      state: 2,
      zip: 10,
      phone: 13,
      fax: 13,
      internalNote: 75,
      notes2: 25,
      notes3: 25,
    };

    if (newValue.length > maxCharacterLength[name]) {
      newValue = newValue.slice(0, maxCharacterLength[name]); // Truncate the value if it exceeds the limit
    }

    // Update the state with the validated value
    setFormData({
      ...formData,
      [name]: newValue,
    });
    // Update the ref with the validated value
    shipperFormRef.current = {
      ...shipperFormRef.current,
      [name]: newValue,
    };
  };
  
  
  console.log("formData",formData)
  return (
    <>
      <form style={{ margin: "10px" }}>
      <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
    <div style={{ flex: 2, marginRight: "10px" }}>
        <InputLabel
            htmlFor="code"
            style={{
                color: "black",
                marginBottom: "5px",
                fontSize: "0.85rem",
            }}
        >
            Shipper/Cons Code
        </InputLabel>
        <TextField
            id="code"
            name="code"
            value={formData.code?.toUpperCase()|| ""}
            onChange={handleChangeData}
            margin="none"
            fullWidth
            variant="outlined"
            size="small"
            InputLabelProps={{ style: { color: "black" } }}
            disabled={errors.code ||editShipper}
        />
    </div>
    <div style={{ flex: 1 }}>
        <InputLabel
            htmlFor="yard"
            style={{
                color: "black",
                marginBottom: "5px",
                fontSize: "0.85rem",
            }}
        >
            Yard
        </InputLabel>
        <Select
            id="yard"
            name="yard"
            // value={formData.yard || ""}
            value={formData.yard || "N"}
            onChange={handleChangeData}
            margin="none"
            fullWidth
            variant="outlined"
            size="small"
            inputProps={{ id: 'yard' }}
            disabled={errors.yard}
        >
            {/* Dropdown options */}
            <MenuItem value="Y">Y</MenuItem>
            <MenuItem value="N">N</MenuItem>
        </Select>
    </div>
</div>

        <InputLabel
          htmlFor="shipperConsName"
          style={{
            color: "black",
            marginTop: "10px",
            fontSize: "0.85rem",
          }}
        >
          Shipper/Cons Name
        </InputLabel>
        <TextField
          id="shipperConsName"
          name="shipperConsName"
          value={formData.shipperConsName?.toUpperCase()|| ""}
          onChange={handleChangeData}
          margin="none"
          fullWidth
          variant="outlined"
          size="small"
          InputLabelProps={{ style: { color: "black" } }}
          disabled={errors.shipperConsName}
        />

        <InputLabel
          htmlFor="address"
          style={{
            color: "black",
            marginTop: "10px",
            fontSize: "0.85rem",
          }}
        >
          Address
        </InputLabel>
        <TextField
          id="address"
          name="address"
          value={formData.address?.toUpperCase()|| ""}
          onChange={handleChangeData}
          margin="none"
          fullWidth
          variant="outlined"
          size="small"
          InputLabelProps={{ style: { color: "black" } }}
          disabled={errors.address}
        />

      
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "10px",
          }}
        >
           <div style={{ flex: 3, marginRight: "10px" }}>
          <InputLabel
          htmlFor="city"
          style={{
            color: "black",
            marginTop: "10px",
            fontSize: "0.85rem",
          }}
        >
          City
        </InputLabel>
        <TextField
          id="city"
          name="city"
          value={formData.city?.toUpperCase()|| ""}
          onChange={handleChangeData}
          margin="none"
          fullWidth
          variant="outlined"
          size="small"
          InputLabelProps={{ style: { color: "black" } }}
          disabled={errors.city}
        />
        </div>
          <div style={{ flex: 1, marginRight: "10px" }}>
            <InputLabel
              htmlFor="state"
              style={{
                color: "black",
                marginTop: "10px",
                fontSize: "0.85rem",
              }}
            >
              State
            </InputLabel>
            <TextField
              id="state"
              //   label="State"
              name="state"
              value={formData.state?.toUpperCase()|| ""}
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              disabled={errors.state}
            />
          </div>
          <div style={{ flex: 2, marginLeft: "10px" }}>
            <InputLabel
              htmlFor="zip2"
              style={{
                color: "black",
                marginTop: "10px",
                fontSize: "0.85rem",
              }}
            >
              Zip Code
            </InputLabel>
            <TextField
              id="zip2"
              name="zip"
              value={formData.zip|| ""}
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              disabled={errors.zip}
            />
          </div>
        </div>
        <InputLabel
          htmlFor="phone"
          style={{
            color: "black",
            marginTop: "10px",
            fontSize: "0.85rem",
          }}
        >
          Phone
        </InputLabel>
        <TextField
          id="phone"
          name="phone"
          value={formData.phone|| ""}
          onChange={handleChangeData}
          margin="none"
          fullWidth
          variant="outlined"
          size="small"
          InputLabelProps={{ style: { color: "black" } }}
          disabled={errors.phone}
        />

        <InputLabel
          htmlFor="internalNote"
          style={{
            color: "black",
            marginTop: "10px",
            fontSize: "0.85rem",
          }}
        >
          Internal Note
        </InputLabel>
        <TextField
          id="internalNote"
          name="internalNote"
          value={formData.internalNote|| ""}
          onChange={handleChangeData}
          margin="none"
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          size="small"
          InputLabelProps={{ style: { color: "black" } }}
          disabled={errors.internalNote}
        />
      </form>
    </>
  );
}
