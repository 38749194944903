import { Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import EdiOrderTableContainer from "./EdiOrderTableContainer";
import { OrdersWrapper } from "../../components/styledComponents";
import EdiOrderFormContainer from "./EdiOrderFormContainer";
import { useSelector, useDispatch } from "react-redux";
import { getEdiOrderData } from "../../store/actions/ediOrderAction";
import { maptoEdiOrderTable } from "./components/commonFn";
export default function EdiOrder() {
  const [selectedRow, setSelectedRow] = useState();
  const [selectedRowNote,setSelectedRowNote]=useState("")
  const [currentEdiJson, setCurrentEdiJson] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowData, setRowdata] = useState([]);
  const [rowsCount, setRowsCount] = useState(1);
  const dispatch = useDispatch();
  const [orderType, setOrderType] = useState("INBOUND");
  const [activeButton, setActiveButton] = useState('Pending');
  const [reqQuery, setReqQuery] = useState({
    pageData: { page: 1, pageSize: 50 },
    sortData: { field: "customer", sort: "asc" },
    searchText: "",
    ediListType:"Pending"
  });

  const [selectedRowData, setSelectedRowData] = useState();
  const [oldEdiOrderData,setOldEdiOrderData]=useState("")
  const [nonMatchedJsonData,setNonMatchedJsonData]=useState([])
  const ordersLocation = useSelector(
    ({ orderReducer }) => orderReducer.ordersLocation
  );

  useEffect(() => {
    setIsLoading(true);
    setSelectedRow("");
    setSelectedRowNote("")

    if ( typeof ordersLocation?.locationNumber !== "undefined") {
      ediTableApiCall();
    }
  }, [ordersLocation]);

  const ediTableApiCall = (
    pageData,
    sortData ,
    searchText,
    ediListType
  ) => {
    // History Unassigned Pending
    setIsLoading(true);
    setReqQuery(prevState => ({
      ...prevState,
      ...(pageData && { pageData }),
      ...(sortData && { sortData }),
      ...(searchText !== undefined && { searchText }),
      ...( ediListType && {  ediListType })
    }));

    dispatch(
      getEdiOrderData({
        locationNumber: ordersLocation?.locationNumber,
        query: pageData?pageData:reqQuery.pageData,
        sortData:sortData?sortData:reqQuery.sortData,
        search:searchText !== undefined ?searchText:reqQuery.searchText,
        ediListType:ediListType?.[0]?ediListType[0]:reqQuery.ediListType[0]
      })
    ).then((res) => {
      if (res?.data) {
        setRowdata(res?.data);
        let data = maptoEdiOrderTable(res?.data);
        setRows(data);
        res?.totalCounts? setRowsCount(res?.totalCounts):setRowsCount(1);
      } else {
        setRowdata(res?.data);
        let data = maptoEdiOrderTable(res?.data);
        setRows(data);
        res?.totalCounts? setRowsCount(res?.totalCounts):setRowsCount(1);
      }
      setIsLoading(false);
    });
  };
  return (
    <OrdersWrapper>
      <Grid container>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <div
            style={{
              height: `calc(100vh - 90px)`,
              width: "100%",
              padding: "10px",
            }}
          >
            <EdiOrderTableContainer
              setSelectedRowData={setSelectedRowData}
              setSelectedRow={setSelectedRow}
              rows={rows}
              isLoading={isLoading}
              rowData={rowData}
              rowsCount={rowsCount}
              selectedRow={selectedRow}
              selectedRowNote={selectedRowNote}
              ediTableApiCall={ediTableApiCall}
              setIsLoading={setIsLoading}
              setRowsCount={setRowsCount}
              activeButton={activeButton}
              setActiveButton={setActiveButton}
              setSelectedRowNote={setSelectedRowNote}
              oldEdiOrderData={oldEdiOrderData}
              setOldEdiOrderData={setOldEdiOrderData}
              nonMatchedJsonData={nonMatchedJsonData}
              currentEdiJson={currentEdiJson}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <div
            style={{
              height: `calc(100vh - 90px)`,
              width: "100%",
              padding: !selectedRow ? "0px" : "10px",
            }}
          >
            {!selectedRow ? (
              <div
                style={{
                  width: "100%",
                  height: `calc(100vh - 90px)`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <h3 style={{ fontSize: "22px", color: "#707070" }}>
                  Click to Open any EDI Order
                </h3>
              </div>
            ) : (
              <div key={selectedRow}>
                <EdiOrderFormContainer
                  selectedRow={selectedRow}
                  selectedRowData={selectedRowData}
                  setOrderType={setOrderType}
                  orderType={orderType}
                  selectedRowNote={selectedRowNote}
                  setSelectedRowNote={setSelectedRowNote}
                  setSelectedRow={setSelectedRow}
                  ediTableApiCall={ediTableApiCall}
                  activeButton={activeButton}
                  oldEdiOrderData={oldEdiOrderData}
                  setOldEdiOrderData={setOldEdiOrderData}
                  setNonMatchedJsonData={setNonMatchedJsonData}
                  setActiveButton={setActiveButton}
                  currentEdiJson={currentEdiJson}
                  setCurrentEdiJson={setCurrentEdiJson}
                />
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </OrdersWrapper>
  );
}
