import { Provider } from 'react-redux';
import { MainRouter } from './scenes/MainRouter';
import store from './store/index';
import { BrowserRouter as Router } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-license-pro';
LicenseInfo.setLicenseKey('c0981cce56b374bf661c85d6fc240e04Tz03NDYzMSxFPTE3MjYyNjY0MTQwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

function App() {
  return (
    <Provider store={store}>
      <Router>
        <MainRouter />
      </Router>
    </Provider>
  );
}

export default App;
