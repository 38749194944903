import { useEffect, useState } from "react";
import { TextField, Autocomplete,Button } from "@mui/material";
import { Select, MenuItem } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import "./TruckDriverDetailEditPanel.css";
import { highlightDate,mapTruckDriverFormToServer,mapDriverList} from './commonFn';
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import { PhoneNumberMask } from '../../../components/TextFieldFormat';
import { useDispatch, useSelector } from "react-redux";
import { getTruckDriverData ,editTruckDriverVendor, getSingleTruckDriverData} from "../../../store/actions/truckdriverActions";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import useUnsavedChangesWarning from '../../../components/Alerts/useUnsavedChangesWarning';
import {getEdiOrderDriverList} from "../../../store/actions/ediOrderAction"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';



export default function TruckDriverDetailEditPanel({
  truckDriverData,
  onClose,
  truckDriverNo,
  fetchDataFromServer,
  rows,
  locationNumber
}) {
  const dispatch = useDispatch();
  const ordersLocation = useSelector(
    ({ orderReducer }) => orderReducer.ordersLocation
  );
  const [formData, setFormData] = useState([]);
  const [errors, setErrors] = useState({});
  const [editTruckDriver, setEditTruckDriver] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackContent, setSnackContent] = useState("");
  const [driverNumberOptions, setDriverNumberOptions] = useState([]);
  const[seletedDriver,setSeletedDriver]=useState("")
  const[showDriverConfirmationDialog,setShowDriverConfirmationDialog]=useState(false)
  const[allDriversList,setAllDriversList]=useState([])
  const condition = editTruckDriver
  useUnsavedChangesWarning(condition);

  useEffect(() => {
    if (truckDriverData) {
      setFormData(truckDriverData);
    }
    if (truckDriverNo) {
      let parts = truckDriverNo.split("-");
      let driverNo = Number(parts[0]);
      let truckNo = Number(parts[1]);

      fetchSingleDataFromServer(truckNo, driverNo);
    }
  }, [truckDriverData, truckDriverNo]);
  function correctSpaceFormat(value) {
    // Replace any non-standard whitespace characters with regular spaces
    return value.replace(/\s+/g, " ");
  }

  const handleChangeData = (event) => {
    const { name, value } = event.target;
    let newValue = correctSpaceFormat(value.toUpperCase()); // Convert value to uppercase

    // Validate the length based on the field name
    const maxCharacterLength = {
      comment: 25,
      truck_driver_label: 25,
      truckPhone: 13,
      condata_card: 25,
      driver_email:256,
      driver_phone_num:13,
      sort:5,
     };

    if (newValue.length > maxCharacterLength[name]) {
      newValue = newValue.slice(0, maxCharacterLength[name]); // Truncate the value if it exceeds the limit
    }

    // Update the state with the validated value
    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const handleCancelClick = () => {
    setEditTruckDriver(false);
  
  };

  const handleCancelEdit = () => {
     setEditTruckDriver(false);
            
  };
 

  const handleSave = () => {
 
      const postObj=mapTruckDriverFormToServer(formData)
      dispatch(editTruckDriverVendor( ordersLocation.locationNumber, postObj))
      .then(
        (res) => {
          console.log("res-shipperForm", res);
          if (
            res?.httpCode === 200 &&
            res?.message === "Record has been updated successfully!"
          ) {
            setSnackOpen(true);
            setSnackContent({
              severity: "success",
              message:` truck/Driver/vendor has updated`,
            });
            setTimeout(() => {
              onClose();
              if(truckDriverData){
                fetchDataFromServer()
              }
            }, 1000); 
 
          } else {
            setSnackOpen(true);
            setSnackContent({
              severity: "error",
              message:res?.message? res?.message:"error"            
                
            });
          }
        }
      );
    ;
  };
  const handleCancelBackToTruckTable=()=>{
    onClose(false)
  }
  const getSharedStyles = (value, isTerminationDate) => {
    const highlightColor = highlightDate(value, true, isTerminationDate);
    const isHighlighted = highlightColor === 'red' || highlightColor === "#f29407";
  
    return {
      '& .Mui-disabled': {
        opacity: isHighlighted ? 1 : 0.8,
        color: isHighlighted ? 'white' : "grey",
        WebkitTextFillColor: isHighlighted ? 'white' : "grey",
      }
    };
  };
  const fetchSingleDataFromServer = (truckNo,driverNo) => {
    setSnackOpen(true);
    setSnackContent({
      severity: "info",
      message: "get data from server...!",
    });
    dispatch(
      getSingleTruckDriverData(
        ordersLocation.locationNumber,
        truckNo,
        driverNo
      )
    ).then((res) => {
    setFormData(res[0])
      setSnackOpen(false);
    });
  };

  const handleDriverNumberLookup = async (query) => {
    const result = [];

rows.forEach((item,index) => {
  if (!item.driver_number) {
    // Skip if driver_number is an empty string or null
    return;
  }
  const existingDriver = result.find(driver => driver.driver_number === item.driver_number);

  if (existingDriver) {
    if(!item.truck){
      return;
    }
    // Add truck number to the existing driver
    existingDriver.trucks.push(item.truck);
  } else {
    // Create a new driver entry
    result.push({
      id:index,
      driver_number: item.driver_number,
      driver_name: item.driver_name,
      driver_status: item.driver_status,
      driver_status_description: item.driver_status_description,
      trucks:item.truck? [item.truck]:[],
      old_driver_number:formData.driver_number||"",
    });
  }
});
setAllDriversList(result)
    dispatch(
      getEdiOrderDriverList(
        ordersLocation.locationNumber
      )
    ).then((res) => {
      const data=mapDriverList(res,formData.driver_number)
      setDriverNumberOptions(data);
    })
   
  };
  
  const handleDriverNumberChange = (event, value) => {
if(value){
  const matchedDriver = allDriversList.find(driver => driver.driver_number === value.driver_number);
  if (matchedDriver && matchedDriver.trucks && matchedDriver.trucks.length > 0) {
    value.trucks = matchedDriver.trucks;
}
 // const finalValue = matchedDriver ? matchedDriver : value;
  setSeletedDriver(value)
  setShowDriverConfirmationDialog(true)
}else{
  handleDriverChangeConfirm("")
}
    
  };
  const handleDriverChangeConfirm=(value)=>{
    setShowDriverConfirmationDialog(false)
    if(value){
      setFormData(prevState => ({
        ...prevState,...value}))
    }else{
      setFormData(prevState => ({
        ...prevState,
        driver_number:"",
        driver_name:"",
        licence_no:"",
        license_exp:"",
        medical_exam_exp:"",
        termination_date:"",
        driver_phone_num:"",
        driver_email:"",
        condata_card:"",
        old_driver_number:formData.old_driver_number
        }))
    }
  };
  return (
    <>
     <div
        style={{
          width: "100%",
          height: "40px",
          backgroundColor: "#E4EEFA",
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          padding: "20px",
          position: "sticky",
          top: 0,
          zIndex: 1000,
        }}
      >
        <div style={{ display: "flex", alignItems: "center", width: "50%" }}>
          <label style={{ fontSize: "14px", color: "#091DA3" }}>
           Truck/Driver/Vendor Information
          </label>
        </div>
    {editTruckDriver ? (
          <div>
            <Button
              variant="outlined"
              style={{
                width: "80px",
                height: "30px",
                fontSize: "10px",
                marginRight: "10px",
                color: "#000000",
              }}
              startIcon={<SaveIcon style={{ width: "14px", height: "14px" }} />}
              onClick={() => {
                handleSave(editTruckDriver ? "edit" : "new");
              }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              style={{
                width: "80px",
                height: "30px",
                fontSize: "10px",
                color: "#000000",
              }}
              startIcon={
                <ClearIcon style={{ width: "14px", height: "14px" }} />
              }
              onClick={editTruckDriver ? handleCancelEdit : handleCancelClick}
            >
              Cancel
            </Button>
          </div>
        ) : (
          <div>
           
            <Button
              variant="outlined"
              style={{
                width: "80px",
                height: "30px",
                fontSize: "10px",
                marginRight: "10px",
                color: "#000000",
              }}
              startIcon={<EditIcon style={{ width: "14px", height: "14px" }} />}
              onClick={() => {
                setEditTruckDriver(true);
              }}
            >
              Edit
            </Button>
            <Button
              variant="outlined"
              style={{
                width: "80px",
                height: "30px",
                fontSize: "10px",
                marginRight: "10px",
                color: "#000000",
              }}
              startIcon={
                <ClearIcon style={{ width: "14px", height: "14px" }} />
              }
              onClick={handleCancelBackToTruckTable}
            >
              Cancel
            </Button>
          
          </div>
        )}
        </div>
        <div style={editTruckDriver ?{}: {pointerEvents: "none", opacity: "0.9" }}>
      <form className="form-container">
        <div className="input-row">
          <div  style={{ flex: 1, marginRight: "10px" }}>
            <InputLabel
              htmlFor="company"
              style={{
                color: "black",
                marginBottom: "5px",
                fontSize: "0.85rem",
              }}
            >
              Company
            </InputLabel>
            <TextField
              id="company"
              name="company"
              value={formData.company || ""}
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              disabled={true}
            />
          </div>
          <div style={{ flex: 1, marginRight: "10px" }}>
            <InputLabel
              htmlFor="location"
              style={{
                color: "black",
                marginBottom: "5px",
                fontSize: "0.85rem",
              }}
            >
              Location
            </InputLabel>
            <TextField
              id="location"
              name="location"
              value={formData.location || ""}
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              disabled={true}
              sx={{
                '& .Mui-disabled': {
                  opacity: 1, // Set opacity to 1 to ensure visibility
                  color: 'rgba(0, 0, 0, 0.6)', // Change the text color if needed
                  WebkitTextFillColor: 'rgba(0, 0, 0, 0.6)' // Ensures the color change in Safari
                }}}
            />
          </div>
          <div style={{ flex: 1, marginRight: "10px" }}>
            <InputLabel
              htmlFor="truck"
              style={{
                color: "black",
                marginBottom: "5px",
                fontSize: "0.85rem",
              }}
            >
              Truck
            </InputLabel>
            <TextField
              id="truck"
              name="truck"
              value={formData.truck|| ""}
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              disabled={true}
            />
          </div>
          <div style={{ flex: 1, marginRight: "10px" }}>
            <InputLabel
              htmlFor="overall_avb"
              style={{
                color: "black",
                marginBottom: "5px",
                fontSize: "0.85rem",
              }}
            >
              Overall Availabilty
            </InputLabel>
            <TextField
              id="overall_avb"
              name="overall_avb"
              value={formData.overall_avb?.toUpperCase() || ""}
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              disabled={true}
            />
          </div>
        </div>

        <InputLabel
          htmlFor="overall_avb_message"
          style={{
            color: "black",
            marginTop: "10px",
            fontSize: "0.85rem",
          }}
        >
          Overall Availability
        </InputLabel>
        <TextField
          id="overall_avb_message"
          name="overall_avb_message"
          value={formData.overall_avb_message?.toUpperCase() || ""}
          onChange={handleChangeData}
          margin="none"
          fullWidth
          variant="outlined"
          size="small"
          InputLabelProps={{ style: { color: "black" } }}
          disabled={true}
        />
        <Divider textAlign="left" style={{ marginTop: "1%" }}>
          <Chip label="Truck Information" size="large" variant="filled" />
        </Divider>

        <InputLabel
          htmlFor="description"
          style={{
            color: "black",
            marginTop: "10px",
            fontSize: "0.85rem",
          }}
        >
          Description
        </InputLabel>
        <TextField
          id="description"
          name="description"
          value={formData.vendor_name?.toUpperCase() || ""}
          onChange={handleChangeData}
          margin="none"
          fullWidth
          variant="outlined"
          size="small"
          InputLabelProps={{ style: { color: "black" } }}
          disabled={true}
        />

        <InputLabel
          htmlFor="comment"
          style={{
            color: "black",
            marginTop: "10px",
            fontSize: "0.85rem",
          }}
        >
          Comment
        </InputLabel>
        <TextField
          id="comment"
          name="comment"
          value={formData.comment?.toUpperCase() || ""}
          onChange={handleChangeData}
          margin="none"
          fullWidth
          variant="outlined"
          size="small"
          InputLabelProps={{ style: { color: "black" } }}
          disabled={errors.comment}
        />
      
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          <div style={{ flex: 1, marginRight: "10px" }}>
            <InputLabel
              htmlFor="truck_status_code"
              style={{
                color: "black",
                marginBottom: "5px",
                
                fontSize: "0.85rem",
              }}
            >
              Safety Truck Status
            </InputLabel>
            <TextField
              id="truck_status_code"
              name="truck_status_code"
              value={formData.truck_status_code?.toUpperCase() || ""}
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              disabled={true}
            />
          </div>
          <div style={{ flex: 6, marginRight: "10px" }}>
            <InputLabel
              htmlFor="truck_status_des"
              style={{
                color: "black",
                marginBottom: "5px",
                fontSize: "0.85rem",
                visibility: "hidden"
              }}
            >
             Safety Truck Status Description
            </InputLabel>
            <TextField
              id="truck_status_des"
              name="truck_status_des"
              value={formData.truck_status_des?.toUpperCase() || ""}
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              disabled={true}
            />
          </div>
          </div>
          <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
             <div style={{ flex: 1, marginRight: "10px" }}>
             <InputLabel
              htmlFor="sort"
              style={{
                color: "black",
                marginBottom: "5px",
                
                fontSize: "0.85rem",
              }}
            >
            Sort
            </InputLabel>
            <TextField
              id="sort"
              name="sort"
              value={formData.sort || ""}
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              disabled={errors.sort}
            />
             </div>
        
           <div style={{ flex: 1, marginRight: "10px" }}>
        <InputLabel
            htmlFor="available"
            style={{
                color: "black",
                marginBottom: "5px",
                fontSize: "0.85rem",
            }}
        >
          Available
        </InputLabel>
        <Select
            id="available"
            name="available"
            value={formData.available || "N"}
            onChange={handleChangeData}
            margin="none"
            fullWidth
            variant="outlined"
            size="small"
            disabled={errors.available}
        >
            {/* Dropdown options */}
            <MenuItem value="Y">Y</MenuItem>
            <MenuItem value="N">N</MenuItem>
        </Select>
    </div>
          <div style={{ flex: 1, marginRight: "10px" }}>
            <InputLabel
              htmlFor="active"
              style={{
                color: "black",
                marginBottom: "5px",
                fontSize: "0.85rem",
               
              }}
            >
             Active
            </InputLabel>
            <TextField
              id="active"
              name="active"
              value={formData.active?.toUpperCase() || ""}
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              disabled={true}
            />
          </div>
          {/* <div style={{ flex: 2, marginRight: "10px" }}>
            <InputLabel
              htmlFor="equipment_licence"
              style={{
                color: "black",
                marginBottom: "5px",
                fontSize: "0.85rem",
               
              }}
            >
             Equipment License
            </InputLabel>
            <TextField
              id="equipment_licence"
              name="equipment_licence"
              value={formData.equipment_license_termination?.toUpperCase() || ""}
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              disabled={true}
            />
          </div> */}
          </div>
          <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
           {/* <CustomTextfield label="Equipment Termination" field="equipment_termination" value={formData.equipment_termination || ""} formData={formData} handleChangeData={handleChangeData}/> */}
          {/* <div style={{ flex: 1, marginRight: "10px" }}>
            <InputLabel
              htmlFor="equipment_termination"
              style={{
                color: "black",
                marginBottom: "5px",
                
                fontSize: "0.85rem",
              }}
            >
             Equipment Termination
            </InputLabel>
            <TextField
              id="equipment_termination"
              name="equipment_termination"
              value={formData.equipment_termination?.toUpperCase() || ""}
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              disabled={errors.equipment_termination}
            />
          </div> */}
          <div style={{ flex: 1, marginRight: "10px" }}>
            <InputLabel
              htmlFor="registration_exp"
              style={{
                color: "black",
                marginBottom: "5px",
                fontSize: "0.85rem",
               
              }}
            >
             Registration Expires
            </InputLabel>
            <TextField
              id="registration_exp"
              name="registration_exp"
              value={formData.registration_exp || ""}
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              inputProps={{style:{backgroundColor: highlightDate(formData.registration_exp, true),}}}
              disabled={true}
              sx={getSharedStyles(formData.registration_exp)}
            />
          </div>
         
          {/* </div>
          <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        > */}
          
          {/* <div style={{ flex: 1, marginRight: "10px" }}>
            <InputLabel
              htmlFor="ex_service_date"
              style={{
                color: "black",
                marginBottom: "5px",
                
                fontSize: "0.85rem",
              }}
            >
             Ex-Service Date
            </InputLabel>
            <TextField
              id="ex_service_date"
              name="ex_service_date"
              value={formData.ex_service_date?.toUpperCase() || ""}
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              InputProps={{ style: { backgroundColor:highlightDate(formData.ex_service_date?.toUpperCase(),true) } }}
              disabled={errors.ex_service_date}
            />
          </div> */}
          <div style={{ flex: 1, marginRight: "10px" }}>
            <InputLabel
              htmlFor="fhwa_insp_exp"
              style={{
                color: "black",
                marginBottom: "5px",
                fontSize: "0.85rem",
               
              }}
            >
             FWHA Inspection Expires
            </InputLabel>
            <TextField
              id="fhwa_insp_exp"
              name="fhwa_insp_exp"
              value={formData.fhwa_insp_exp || ""}
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              inputProps={{style:{backgroundColor: highlightDate(formData.fhwa_insp_exp, true),}}}
              disabled={true}
              sx={getSharedStyles(formData.fhwa_insp_exp)}
            />
          </div>
         
          </div>
          <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          <div style={{ flex: 1, marginRight: "10px" }}>
            <InputLabel
              htmlFor="drivername"
              style={{
                color: "black",
                marginBottom: "5px",
                
                fontSize: "0.85rem",
              }}
            >
            Truck Driver/Label
            </InputLabel>
            <TextField
              id="drivername"
              name="truck_driver_label"
              value={formData.truck_driver_label?.toUpperCase() || ""}
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              disabled={errors.truck_driver_label}
            />
          </div>
          <div style={{ flex: 1, marginRight: "10px" }}>
            <InputLabel
              htmlFor="phone_number"
              style={{
                color: "black",
                marginBottom: "5px",
                fontSize: "0.85rem",
               
              }}
            >
             Phone
            </InputLabel>
            <PhoneNumberMask
            label=""
            size="small"
            className="width_100"
            value={formData.truckPhone || ''}
            inputProps={{ style: { textTransform: "uppercase" }, maxLength: 10 }}
            onChange={event => {
                setFormData({ ...formData, truckPhone: event.target.value })
            }}
            disabled={!editTruckDriver}
            />
          </div>
         
          </div>
          <Divider textAlign="left" style={{ marginTop: "1%" }}>
          <Chip label="Vendor" size="large" variant="filled" />
        </Divider>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          <div style={{ flex: 1, marginRight: "10px" }}>
            <InputLabel
              htmlFor="vendor_code"
              style={{
                color: "black",
                marginBottom: "5px",
                
                fontSize: "0.85rem",
              }}
            >
           Code
            </InputLabel>
            <TextField
              id="vendor_code"
              name="vendor_code"
              value={formData.vendor_code || ""}
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              disabled={true}
            />
          </div>
          <div style={{ flex: 3, marginRight: "10px" }}>
            <InputLabel
              htmlFor="vendor_name"
              style={{
                color: "black",
                marginBottom: "5px",
                fontSize: "0.85rem",
               
              }}
            >
             Name
            </InputLabel>
            <TextField
              id="vendor_name"
              name="vendor_name"
              value={formData.vendor_name?.toUpperCase() || ""}
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              disabled={true}
            />
          </div>
         
          </div>
          <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          <div style={{ flex: 1, marginRight: "10px" }}>
            <InputLabel
              htmlFor="vendor_address"
              style={{
                color: "black",
                marginBottom: "5px",
                
                fontSize: "0.85rem",
              }}
            >
            Address
            </InputLabel>
            <TextField
              id="vendor_address"
              name="vendor_address"
              value={formData.vendor_address?.toUpperCase() || ""}
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              disabled={true}
            />
          </div>
          <div style={{ flex: 1, marginRight: "10px" }}>
            <InputLabel
              htmlFor="vendor_city_st_zip"
              style={{
                color: "black",
                marginBottom: "5px",
                fontSize: "0.85rem",
               
              }}
            >
             City/St/Zip
            </InputLabel>
            <TextField
              id="vendor_city_st_zip"
              name="vendor_city_st_zip"
              value={formData.vendor_city_st_zip?.toUpperCase() || ""}
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              disabled={true}
            />
          </div>
         
          </div>
          <Divider textAlign="left" style={{ marginTop: "1%" }}>
          <Chip label="Driver" size="large" variant="filled" />
        </Divider>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          {/* <div style={{ flex: 1, marginRight: "10px" }}>
            <InputLabel
              htmlFor="driver_number"
              style={{
                color: "black",
                marginBottom: "5px",
                
                fontSize: "0.85rem",
              }}
            >
           Driver Number
            </InputLabel>
            <TextField
              id="driver_number"
              name="driver_number"
              value={formData.driver_number || ""}
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              disabled={true}
            />
          </div> */}
          <div style={{ flex: 2, marginRight: "10px" }}>
  <InputLabel
    htmlFor="driver_number"
    style={{
      color: "black",
      marginBottom: "5px",
      fontSize: "0.85rem",
    }}
  >
    Driver Number
  </InputLabel>
  <Autocomplete
    disablePortal
    id="driver_number"
    size="small"
    // value={formData?.driver_number}
    value={formData}
    className="width_100"
    options={driverNumberOptions}
    getOptionLabel={(option) => option ? `${option.driver_number}` : ""}
    renderOption={(props, option) => (
      <li {...props} key={props['data-option-index']} style={{ borderBottom: '1px solid #E0E0E0' }}>
        {`${option.driver_number} - ${option.driver_name}`}
      </li>
    )}
    isOptionEqualToValue={(option, value) => option.driver_number === value.driver_number || option === ""}
    renderInput={(params) => (
      <TextField
        {...params}
        
        // label="Driver Number"
        InputLabelProps={{ style: { color: "black" } }}
        sx={{
          input: {
            // background: isTemplateScreen ? null : "#F8F0E3",
            fontSize: '14px',
            textTransform: 'uppercase'
          }
        }}
      />
    )}
    onInputChange={(event, value) => {
      if (value.length >= 2) {
        handleDriverNumberLookup(value);
      }
    }}
    onChange={handleDriverNumberChange}
    componentsProps={{
      paper: {
        sx: {
          width: 500
        }
      }
    }}
    // disabled={disableFull}
  />
</div>
          <div style={{ flex: 3, marginRight: "10px" }}>
            <InputLabel
              htmlFor="driver_name"
              style={{
                color: "black",
                marginBottom: "5px",
                fontSize: "0.85rem",
               
              }}
            >
             Driver Name
            </InputLabel>
            <TextField
              id="driver_name"
              name="driver_name"
              value={formData.driver_name?.toUpperCase() || ""}
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              disabled={true}
            />
          </div>
         
          </div>
          <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          <div style={{ flex: 1, marginRight: "10px" }}>
            <InputLabel
              htmlFor="license_exp"
              style={{
                color: "black",
                marginBottom: "5px",
                
                fontSize: "0.85rem",
              }}
            >
            Licence Expires
            </InputLabel>
            <TextField
              id="code"
              name="license_exp"
              value={formData.license_exp || ""}
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              inputProps={{style:{backgroundColor:formData.license_exp?highlightDate(formData.license_exp, true):""}}}
              disabled={true}
              sx={getSharedStyles(formData.license_exp)}
            />
          </div>
          <div style={{ flex: 1, marginRight: "10px" }}>
            <InputLabel
              htmlFor="licence_no"
              style={{
                color: "black",
                marginBottom: "5px",
                fontSize: "0.85rem",
               
              }}
            >
             Licence No
            </InputLabel>
            <TextField
              id="licence_no"
              name="licence_no"
              value={formData.licence_no || ""}
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              disabled={true}
            />
          </div>
         
          </div>
          <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          <div style={{ flex: 1, marginRight: "10px" }}>
            <InputLabel
              htmlFor="medical_exam_exp"
              style={{
                color: "black",
                marginBottom: "5px",
                
                fontSize: "0.85rem",
              }}
            >
            Medical Exam Expires
            </InputLabel>
            <TextField
              id="medical_exam_exp"
              name="medical_exam_exp"
              value={formData.medical_exam_exp || ""}
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              inputProps={{style:{backgroundColor: formData.driver_number ?highlightDate(formData.medical_exam_exp, true):""}}}
              sx={getSharedStyles(formData.medical_exam_exp)}
              disabled={true}
              
            />
          </div>
          {/* <div style={{ flex: 1, marginRight: "10px" }}>
            <InputLabel
              htmlFor="annual_review_exp"
              style={{
                color: "black",
                marginBottom: "5px",
                fontSize: "0.85rem",
               
              }}
            >
             Annual Review Expires
            </InputLabel>
            <TextField
              id="annual_review_exp"
              name="annual_review_exp"
              value={formData.annual_review_exp?.toUpperCase() || ""}
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              inputProps={{style:{backgroundColor: highlightDate(formData.annual_review_exp, true),}}}
              disabled={true}
              sx={getSharedStyles(formData.annual_review_exp)}
            /> 
          </div>*/}
          <div style={{ flex: 1, marginRight: "10px" }}>
            <InputLabel
              htmlFor="termination_date"
              style={{
                color: "black",
                marginBottom: "5px",
                fontSize: "0.85rem",
               
              }}
            >
             Termination Date
            </InputLabel>
            <TextField
              id="termination_date"
              name="termination_date"
              value={formData.termination_date || ""}
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              inputProps={{style:{backgroundColor:formData.driverNo? highlightDate(formData.termination_date, true,true):""}}}
              disabled={true}
              sx={getSharedStyles(formData.termination_date,true)}
            />
          </div>
         
          </div>
          <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          <div style={{ flex: 1, marginRight: "10px" }}>
            <InputLabel
              htmlFor="driver_phone_num"
              style={{
                color: "black",
                marginBottom: "5px",
                
                fontSize: "0.85rem",
              }}
            >
            Phone Number
            </InputLabel>
            <PhoneNumberMask
            label=""
            size="small"
            className="width_100"
            value={formData.driver_phone_num || ''}
            inputProps={{ style: { textTransform: "uppercase" }, maxLength: 10 }}
            onChange={event => {
                setFormData({ ...formData, driver_phone_num: event.target.value })
            }}
            disabled={!editTruckDriver}
            />
            </div>
          </div>
          <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          <div style={{ flex: 1, marginRight: "10px" }}>
            <InputLabel
              htmlFor="driver_email"
              style={{
                color: "black",
                marginBottom: "5px",
                
                fontSize: "0.85rem",
              }}
            >
            Email Address
            </InputLabel>
            <TextField
              id="driver_email"
              name="driver_email"
              value={formData.driver_email?.toUpperCase() || ""}
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              disabled={errors.driver_email}
            />
          </div>
          <div style={{ flex: 1, marginRight: "10px" }}>
            <InputLabel
              htmlFor="code"
              style={{
                color: "black",
                marginBottom: "5px",
                fontSize: "0.85rem",
               
              }}
            >
             Comdata Card
            </InputLabel>
            <TextField
              id="code"
              name="condata_card"
              value={formData.condata_card?.toUpperCase() || ""}
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              disabled={errors.condata_card}
            />
          </div>
         
          </div>

        
      </form>
      <Snackbar
        open={snackOpen}
        style={{ marginTop: "5%" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setSnackOpen(false)}
      >
        <Alert
          onClose={() => setSnackOpen(false)}
          severity={snackContent.severity}
          sx={{ width: "100%" }}
        >
          {snackContent.message}
        </Alert>
      </Snackbar>
      <Dialog
      open={showDriverConfirmationDialog}
      onClose={() => setShowDriverConfirmationDialog(false)}
      PaperProps={{
        style: {
            width: '500px', // Set the desired width
            height: 'auto', // Set the desired height
        },
    }}>
    
      <DialogTitle> {`Do you want to proceed  with assigning Driver Number- ${seletedDriver?.driver_number}(${seletedDriver?.driver_name}) to Truck Number- ${formData?.truck} ?`}</DialogTitle>
      
<DialogContent>

{seletedDriver?.trucks?.length > 0?<DialogContentText id="alert-dialog-description">
    The driver was previously assigned to these trucks -
    <b>
    {seletedDriver?.trucks?.length === 0
      ? "None"
      : seletedDriver?.trucks?.length === 1
      ? seletedDriver?.trucks[0]
      : `${seletedDriver?.trucks?.slice(0, -1).join(", ")} and ${seletedDriver?.trucks?.slice(-1)}`}
      </b>
  </DialogContentText>:null}
  {seletedDriver?.driver_status_description?<DialogContentText id="alert-dialog-description">
 Driver Status Description: {seletedDriver?.driver_status_description}
</DialogContentText>:null}
</DialogContent>
      <DialogActions>
        <Button onClick={()=>{setShowDriverConfirmationDialog(false)}}  color="primary">
          No 
        </Button>
        <Button onClick={()=>{handleDriverChangeConfirm(seletedDriver)}} color="primary" >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
      </div>
    </>
  );
}
