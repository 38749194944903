import moment from 'moment';
/**
 * Convert military time to hh:mm
 * @param {*} timeVal
 * @returns
 */
export const convertMilitaryTimeToHHMM = (timeVal) => {
    if (!timeVal || timeVal === 0)
        return "";

    // Convert timeVal to string for manipulation
    let timeString = timeVal.toString();

    // Pad with zeros if the length is less than 4
    while (timeString.length < 4) {
        timeString = "0" + timeString;
    }

    // Extract hours and minutes parts
    const hours = timeString.substring(0, 2);
    const minutes = timeString.substring(2);
    // Return formatted time
    return `${hours}:${minutes}`;
}

/**
 * Convert Military date to YMD
 */
 export const convertMilitaryDateToYMD = (dateVal) => {
    if (dateVal === 0 || !dateVal)
        return ''
    let date = dateVal.toString().substring(1)
    date = `20${date.substring(0, 2)}-${date.substring(2, 4)}-${date.substring(4)}`
    if (moment(date, 'YYYY-MM-DD', true).isValid()) {
        return moment(date).format('MM/DD/YY');
    }
    return '';
}

/**
 * Convert YMD date to MDY
 * @param {*} dateVal
 * @returns
 */
export const convertYMDToMDY = (dateVal) => {
    if (dateVal === 0 || !dateVal)
        return 0
    if (moment(dateVal, 'YYYY-MM-DD', true).isValid()) {
        return moment(dateVal).format('MM/DD/YY');
    }
    return dateVal;
}

/**
 * Convert MDY date to YMD
 * @param {*} dateVal
 * @returns
 */
export const convertMDYToYMD = (dateVal) => {
    if (dateVal === 0 || !dateVal)
        return 0
    if (moment(dateVal, 'YYYY-MM-DD', true).isValid() === false) {
        return moment(dateVal).format('YYYY-MM-DD');
    }
    return dateVal;
}

/**
 * Sanitize string & trim characters to match database field size
 */
 export const sanitizeStringForDBField = (str, length, removeSpecialChars=false) => {
    if (str === null || typeof str === 'undefined') {
        return '';
    }
    let newStr = str.substring(0, length);
    if(removeSpecialChars){
        return newStr.replace(/[^a-zA-Z0-9]/g, '');
    }
    return newStr;
}

export const isNullOrZeroOrEmptyOrUndefined = (value) => {
    // Check if the value is undefined, null, or an empty string
    if (value === undefined || value === null || value === '') {
      return true;
    }

    // Check if the value is zero (you can include zero in the conditions if needed)
    if (typeof value === 'number' && value === 0) {
      return true;
    }

    // Check if the value is an empty array
    if (Array.isArray(value) && value.length === 0) {
      return true;
    }

    // Check if the value is an empty object
    if (typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length === 0) {
      return true;
    }

    // If none of the conditions match, return false
    return false;
  }

export const convertDateToCentury = (dateVal) => {
    // if (!dateVal || dateVal.trim() === "") {
    //     return "";
    // }
    const momentDate = moment(dateVal, ['MM-DD-YY', 'MM/DD/YY', 'MM/DD/YYYY','1YYMMDD','YYYY-MM-DD'], true);

    if (momentDate.isValid()) {
        return "1" + momentDate.format('YYMMDD');
    } else {
        return "";
    }

}