import { useEffect, useState } from "react";
import { Paper, TableContainer, Box, TextField, Button, InputAdornment } from "@mui/material";
import EdiOrderTable from "./components/EdiOrderTable";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close'


export default function EdiOrderTableContainer(props) {
    const { setSelectedRow, setSelectedRowData, rows, isLoading,  rowData,rowsCount,selectedRow,ediTableApiCall,selectedRowNote,setIsLoading,setRowsCount,activeButton,setActiveButton,setSelectedRowNote,oldEdiOrderData,setOldEdiOrderData,nonMatchedJsonData,currentEdiJson} = props;
    const [searchText, setSearchText] = useState('')

    const handleSearchText = (event) => {
        setSearchText(event);
        if (event === "") {
          handleSearch("");
        }
      };
      const handleSearch = (searchName) => {
        setIsLoading(true)
         ediTableApiCall(
            { page: 1, pageSize: 50 },
           "",
            searchName,
            activeButton,
          );
        
    }
    useEffect(() => {

    }, [rows])


 
    const handleQueryPageChange = (
        params,
        sortData = {
          field: "name",
          sort: "asc",
        },
       
      ) => {
        setSelectedRow("");
          ediTableApiCall(
            { page: params?.page + 1, pageSize: params?.pageSize },
            sortData,
            searchText,
            activeButton,
          );
        // }
      };
      const handleSortChange = (
        params,
        sortData = {
          field: "name",
          sort: "asc",
        }
      ) => {
        setSelectedRow("");
        ediTableApiCall(
            { page: params?.page + 1, pageSize: params?.pageSize },
            sortData,
            searchText,
            activeButton,
          );
      };

    const handleButtonClick = (button) => {
        setActiveButton(button);
        setSelectedRow("")
        setSelectedRowNote("")
        // setRowsCount(0)
        ediTableApiCall({ page: 1, pageSize: 50 },{ field: "customer", sort: "asc" },"",button)
    };
   
    return (
        <Paper style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>

            <div style={{ width: '100%', height: '40px', backgroundColor: '#E4EEFA', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                <label style={{ fontSize: '14px', color: '#091DA3' }}>EDI Orders & Changes</label>
            </div>

            <div style={{ width: '100%', height: '50px', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px' }}>
                <div style={{ width: '70%', height: '40px', display: 'flex', alignItems: 'center', paddingRight: '5px' }}>
                    <TextField
                        label="Search"
                        margin="dense"
                        style={{ height: '40px', width: "100%", fontSize: '10px' }}
                        size="small"
                        inputProps={{ style: { fontSize: 10 } }}
                        InputLabelProps={{ style: { fontSize: 14, height: 40 } }}
                        value={searchText}
                        // onChange={(event) => { setSearchText(event.target.value) }}
                        onChange={(event) => {
                            handleSearchText(event.target.value)

                        }}
                        onKeyPress={(event) => { event.key === 'Enter' && handleSearch(event.target.value) }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment>
                                    <IconButton onClick={() => { searchText ? handleSearchText("") : handleSearch() }}>
                                        {searchText ? <CloseIcon /> : <SearchIcon />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}

                    />
                </div>


            </div>
            <div>
             <div style={{ display: 'flex', justifyContent: 'space-around', padding: '10px' }}>
                <Button
                    variant={activeButton === 'Pending' ? 'contained' : 'outlined'}
                    onClick={() => handleButtonClick('Pending')}
                >
                    Pending {rowsCount?.totalPendingCount}
                </Button>
                <Button
                    variant={activeButton === 'Unassigned' ? 'contained' : 'outlined'}
                    onClick={() => handleButtonClick('Unassigned')}
                >
                    Unassigned {rowsCount?.totalUnAssignedCount}
                </Button>
                {/* History {rowsCount?.totalHistoryCount} */}
                <Button
                    variant={activeButton === 'History' ? 'contained' : 'outlined'}
                    onClick={() => handleButtonClick('History')}
                >
                    History
                </Button>
            </div>
        
            </div>

            <div style={{ width: '100%', height: `calc(100vh - 200px)` }}>
                    <EdiOrderTable
                        rows={rows}
                        setSelectedRow={setSelectedRow}
                        setSelectedRowData={setSelectedRowData}
                        rowData={rowData}
                        isLoading={isLoading}
                        handleQueryPageChange={handleQueryPageChange}
                        rowsCount={rowsCount?.totalCount}
                        handleSortChangeData ={handleSortChange }
                        activeButton={activeButton}
                        selectedRow={selectedRow}
                        selectedRowNote={selectedRowNote}
                        oldEdiOrderData={oldEdiOrderData}
                        setOldEdiOrderData={setOldEdiOrderData}
                        nonMatchedJsonData={nonMatchedJsonData}
                        currentEdiJson={currentEdiJson}   
                    />
            </div>
        </Paper>

    )

}