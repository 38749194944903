import { useState } from 'react';
import {
    Button
} from "@mui/material";
import TenderModal from "./TenderModal";
import UntenderButton from './UntenderButton';

export default function TenderButton(props) {
    const { row, type, btnLabel } = props
    const [modalState, setModalState] = useState(false);

    return (
        <>
            <div style={{ paddingRight: '3px' }}>
                <a href={`/orders/${row.locationNumber}/${row.control}`} target='_blank' >{row.control}</a>
            </div>
            <Button
                title='Tender'
                variant="contained"
                onClick={() => setModalState(true)}
                style={{ maxWidth: '13px', maxHeight: '16px', minWidth: '13px', minHeight: '16px', fontSize: '10px', padding: '2px' }}
            >{btnLabel}</Button>
            {type == 'driver' ? (<UntenderButton row={row} />) : null}
            <TenderModal
                modalState={modalState}
                handleModalState={(state) => setModalState(state)}
                type={type}
                row={row}
            />
        </>
    )
}