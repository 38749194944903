import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs from 'dayjs';
import Button from '@mui/material/Button';

const shortcutsItems = [
  {
    label: 'This Week',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('week'), today.endOf('week')];
    },
  },
  {
    label: 'Last Week',
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, 'day');
      return [prevWeek.startOf('week'), prevWeek.endOf('week')];
    },
  },
  {
    label: 'Last 7 Days',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, 'day'), today];
    },
  },
  {
    label: 'Current Month',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('month'), today.endOf('month')];
    },
  },
  {
    label: 'Next Month',
    getValue: () => {
      const today = dayjs();
      const startOfNextMonth = today.endOf('month').add(1, 'day');
      return [startOfNextMonth, startOfNextMonth.endOf('month')];
    },
  },
  { label: 'Reset', getValue: () => [null, null] },
];

function CustomRangeShortcuts({ items, onSelectShortcut }) {
  if (!items || items.length === 0) return null;

  return (
    <Box sx={{ gridRow: 1, gridColumn: 2 }}>
      <List
        dense
        sx={(theme) => ({
          display: 'flex',
          px: theme.spacing(4),
          '& .MuiListItem-root': {
            pt: 0,
            pl: 0,
            pr: theme.spacing(1),
          },
        })}
      >
        {items.map((item) => (
          <ListItem key={item.label}>
            <Chip
              label={item.label}
              onClick={() => {
                const newValue = item.getValue();
                onSelectShortcut(newValue); // Pass the selected range when a shortcut is clicked
              }}
            />
          </ListItem>
        ))}
      </List>
      <Divider />
    </Box>
  );
}

export default function CustomDateRangePicker({ indexValue, onDateSelect, defaultDateRangeValue ,handleCloseDatePicker}) {
  const [selectedDateRange, setSelectedDateRange] =useState([null, null]);

  const convertDateRange = (inputDateRange) => {
    const dateParts = inputDateRange.split('|');
    if(dateParts[0]&&dateParts[1] ){

    // Helper function to convert date string to ISO format
    const convertToISO = (dateStr, isEndOfDay = false) => {
        const year = `20${dateStr.substring(1, 3)}`;
        const month = dateStr.substring(3, 5) - 1;
        const day = dateStr.substring(5, 7);

        let date = new Date(Date.UTC(year, month, day));

        if (isEndOfDay) {
            date.setUTCHours(18, 29, 59, 999); // End of day adjustment
        } else {
            // date.setUTCHours(18, 30, 0, 0); // Start of day adjustment
            date.setUTCHours(0, 0, 0, 0); // Start of day adjustment
        }

        return date.toISOString();
    };

    // Convert start and end dates
    const formattedStartDate = convertToISO(dateParts[0]);
    const formattedEndDate = convertToISO(dateParts[1], true);
    setSelectedDateRange([dayjs(formattedStartDate), dayjs(formattedEndDate)]);
  }else{
    setSelectedDateRange([null,null])
  }

};

  useEffect(() => {
    if(defaultDateRangeValue){
    convertDateRange( defaultDateRangeValue); // Call the conversion function
    }
}, [ defaultDateRangeValue]);

  const handleDateChange = (newRange) => {
    setSelectedDateRange(newRange); // Update state with the new range
  };

  const handleOkayClick = () => {
    // if (onDateSelect) {
      onDateSelect(indexValue, selectedDateRange); // Pass indexValue and selected range to parent
    // }
  };

  const handleShortcutSelect = (newRange) => {
    setSelectedDateRange(newRange); // Update state when a shortcut is selected
    onDateSelect(indexValue, newRange); // Pass indexValue and new range to parent immediately
  };
  const handleClose = () => {
    handleCloseDatePicker() // Close the picker when "Cancel" is clicked
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StaticDateRangePicker
        value={selectedDateRange}
        onChange={handleDateChange} // Trigger when dates are selected in the calendar
        onAccept={handleOkayClick} // Trigger when "OK" is pressed (direct interaction with picker)
        onClose={handleClose} 
        
        slots={{
          shortcuts: (props) => (
            <CustomRangeShortcuts {...props} onSelectShortcut={handleShortcutSelect} />
          ),
        }}
        slotProps={{
          shortcuts: {
            items: shortcutsItems, // Pass the shortcuts for selection
          },
          toolbar: {
            hidden: true,
          },
          actionBar: {
            hidden: false,
          },
        }}
        calendars={2}
      />
       {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleOkayClick}>
          OK
        </Button>
      </Box> */}
    </LocalizationProvider>
  );
}
