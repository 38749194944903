import { Grid } from "@mui/material";
import OrderBy from "./OrderBy";
import PickupDetails from "./PickupDetails";
import Shipper from "./Shipper";
import { useEffect, useState } from "react";
import EquipmentDetailsForm from "./EquipmentDetails";
import CustomerNotes from "./CustomerNotes";
import DriverNotes from "./DriverNotes";
import MasterLoadDetails from "./MasterLoadDetails";

const GridContainer = (props) => (
    <Grid container   {...props.gridProps}>
        {props.children}
    </Grid>
)

const GridItem = (props) => (
    <Grid item {...props.gridProps} >
        {props.children}
    </Grid>
)

export default function BillingControl(props) {

    const { billingControlRef, stopsMasterLoad, orderType, orderTypeOptions, disableFull,toTemplate,oldEdiOrderData,nonMatchingFields, isEdiScreen,newContactsEdi,setFilteredNewContacts } = props

    const [selectedOrderType, setSelectedOrderType] = useState(null)
    // Selected ORder Type
    useEffect(() => {
        if (orderType) {
            const selected = orderTypeOptions.filter(item => item.value == orderType)
            setSelectedOrderType(selected[0].value)
        }
    }, [orderType])
    return (
        <GridContainer>
        {!toTemplate?  <GridContainer gridProps={{ container: true, alignItems: 'stretch' }}>
                <GridItem gridProps={{ xs: 12, md:toTemplate===true?6:4, lg: toTemplate===true?6:4, textAlign: 'end' }}>
                    <OrderBy billingControlRef={billingControlRef} disableFull={disableFull} isTemplateScreen={toTemplate} nonMatchingFields={nonMatchingFields} isEdiScreen={isEdiScreen}/>
                </GridItem>
                <GridItem gridProps={{ xs: 12, md: toTemplate===true?6:4, lg: toTemplate===true?6:4 }}>
                    <EquipmentDetailsForm billingControlRef={billingControlRef} selectedOrderType={selectedOrderType} disableFull={disableFull} isTemplateScreen={toTemplate} nonMatchingFields={nonMatchingFields}/>
                </GridItem>
                <GridItem gridProps={{ xs: 12, md: toTemplate===true?6:4, lg: toTemplate===true?6:4 }}>
                    <CustomerNotes billingControlRef={billingControlRef} selectedOrderType={selectedOrderType} disableFull={disableFull} isTemplateScreen={toTemplate}/>
                </GridItem>
            {/* </GridContainer>
            <GridContainer gridProps={{}}> */}
                <GridItem gridProps={{ xs: 12, md: (orderType === 'INBOUND' || orderType === 'OUTBOUND') ? toTemplate===true?6:4 : toTemplate===true?10:6, lg: (orderType === 'INBOUND' || orderType === 'OUTBOUND') ? toTemplate===true?6:4 : toTemplate===true?10:6, textAlign: 'end' }}>
                    <PickupDetails billingControlRef={billingControlRef} selectedOrderType={selectedOrderType} disableFull={disableFull} isTemplateScreen={toTemplate}  isEdiScreen={ isEdiScreen}/>
                </GridItem>
                {
                    (orderType === 'INBOUND' || orderType === 'OUTBOUND') &&
                    <GridItem gridProps={{ xs: 12, md: toTemplate===true?4:2, lg: toTemplate===true?4:2 }}>
                        <Shipper billingControlRef={billingControlRef} selectedOrderType={selectedOrderType} disableFull={disableFull} isTemplateScreen={toTemplate} isEdiScreen={isEdiScreen}/>
                    </GridItem>
                }
                <GridItem gridProps={{ xs: 12, md: 2, lg: 2 }}>
                    <MasterLoadDetails billingControlRef={billingControlRef} stopsMasterLoad={stopsMasterLoad} selectedOrderType={selectedOrderType} disableFull={disableFull} isTemplateScreen={toTemplate} isEdiScreen={isEdiScreen}/>
                </GridItem>
                <GridItem gridProps={{ xs: 12, md: (orderType === 'INBOUND' || orderType === 'OUTBOUND') ? toTemplate===true?6:4: toTemplate===true?2:4, lg: (orderType === 'INBOUND' || orderType === 'OUTBOUND') ? toTemplate===true?6:4: toTemplate===true?2:4 }}>
                    <DriverNotes billingControlRef={billingControlRef} selectedOrderType={selectedOrderType} disableFull={disableFull} isTemplateScreen={toTemplate} nonMatchingFields={nonMatchingFields}/>
                </GridItem>
            </GridContainer>
     :<>
           {(orderType === 'INBOUND' || orderType === 'OUTBOUND')? <GridContainer gridProps={{}}>
                <GridItem gridProps={{ xs: 12, md:toTemplate===true?6:4, lg: toTemplate===true?6:4, textAlign: 'end' }}>
                    <OrderBy billingControlRef={billingControlRef} disableFull={disableFull} isTemplateScreen={toTemplate} nonMatchingFields={nonMatchingFields} newContactsEdi={newContactsEdi} isEdiScreen={isEdiScreen}setFilteredNewContacts={setFilteredNewContacts}/>
                </GridItem>
                <GridItem gridProps={{ xs: 12, md: toTemplate===true?6:4, lg: toTemplate===true?6:4 }}>
                    <EquipmentDetailsForm billingControlRef={billingControlRef} selectedOrderType={selectedOrderType} disableFull={disableFull} isTemplateScreen={toTemplate} nonMatchingFields={nonMatchingFields}/>
                </GridItem>
                <GridItem gridProps={{ xs: 12, md: (orderType === 'INBOUND' || orderType === 'OUTBOUND') ? toTemplate===true?6:4 : toTemplate===true?12:8, lg: (orderType === 'INBOUND' || orderType === 'OUTBOUND') ? toTemplate===true?6:4 : toTemplate===true?12:8, textAlign: 'end' }}>
                    <PickupDetails billingControlRef={billingControlRef} selectedOrderType={selectedOrderType} disableFull={disableFull} isTemplateScreen={toTemplate} isEdiScreen={ isEdiScreen}/>
                </GridItem>
                <GridItem gridProps={{ xs: 12, md: toTemplate===true?6:4, lg: toTemplate===true?6:4 }}>
                    <MasterLoadDetails billingControlRef={billingControlRef} stopsMasterLoad={stopsMasterLoad} selectedOrderType={selectedOrderType} disableFull={disableFull} isTemplateScreen={toTemplate} isEdiScreen={isEdiScreen}/>
                </GridItem>
                {
                    (orderType === 'INBOUND' || orderType === 'OUTBOUND') &&
                    <GridItem gridProps={{ xs: 12, md: toTemplate===true?6:4, lg: toTemplate===true?6:4 }}>
                        <Shipper billingControlRef={billingControlRef} selectedOrderType={selectedOrderType} disableFull={disableFull} isTemplateScreen={toTemplate} isEdiScreen={isEdiScreen}/>
                    </GridItem>
                }
                <GridItem gridProps={{ xs: 12, md: toTemplate===true?6:4, lg: toTemplate===true?6:4 }}>
                    <DriverNotes billingControlRef={billingControlRef} selectedOrderType={selectedOrderType} disableFull={disableFull} isTemplateScreen={toTemplate} nonMatchingFields={nonMatchingFields}/>
                </GridItem>
                <GridItem gridProps={{ xs: 12, md: toTemplate===true?6:4, lg: toTemplate===true?6:4 }}>
                    <CustomerNotes billingControlRef={billingControlRef} selectedOrderType={selectedOrderType} disableFull={disableFull} isTemplateScreen={toTemplate}/>
                </GridItem>
            {/* </GridContainer>
            <GridContainer gridProps={{}}> */}
              
               
            </GridContainer>
            :<GridContainer gridProps={{}}>
            <GridItem gridProps={{ xs: 12, md:toTemplate===true?6:4, lg: toTemplate===true?6:4, textAlign: 'end' }}>
                <OrderBy billingControlRef={billingControlRef} disableFull={disableFull} isTemplateScreen={toTemplate} nonMatchingFields={nonMatchingFields} newContactsEdi={newContactsEdi} isEdiScreen={isEdiScreen} setFilteredNewContacts={setFilteredNewContacts}/>
                <PickupDetails billingControlRef={billingControlRef} selectedOrderType={selectedOrderType} disableFull={disableFull} isTemplateScreen={toTemplate} isEdiScreen={ isEdiScreen}/>
                <DriverNotes billingControlRef={billingControlRef} selectedOrderType={selectedOrderType} disableFull={disableFull} isTemplateScreen={toTemplate} nonMatchingFields={nonMatchingFields}/>
            </GridItem>
            <GridItem gridProps={{ xs: 12, md: toTemplate===true?6:4, lg: toTemplate===true?6:4 }}>
                <EquipmentDetailsForm billingControlRef={billingControlRef} selectedOrderType={selectedOrderType} disableFull={disableFull} isTemplateScreen={toTemplate} nonMatchingFields={nonMatchingFields}/>
                <MasterLoadDetails billingControlRef={billingControlRef} stopsMasterLoad={stopsMasterLoad} selectedOrderType={selectedOrderType} disableFull={disableFull} isTemplateScreen={toTemplate} isEdiScreen={isEdiScreen}/>
                <CustomerNotes billingControlRef={billingControlRef} selectedOrderType={selectedOrderType} disableFull={disableFull} isTemplateScreen={toTemplate}/>
            </GridItem>
            {/* <GridItem gridProps={{ xs: 12, md: toTemplate===true?6:4, lg: toTemplate===true?6:4 }}>
                <CustomerNotes billingControlRef={billingControlRef} selectedOrderType={selectedOrderType} disableFull={disableFull} isTemplateScreen={toTemplate}/>
            </GridItem> */}
        {/* </GridContainer>
        <GridContainer gridProps={{}}> */}
            {/* <GridItem gridProps={{ xs: 12, md: (orderType === 'INBOUND' || orderType === 'OUTBOUND') ? toTemplate===true?6:4 : toTemplate===true?12:8, lg: (orderType === 'INBOUND' || orderType === 'OUTBOUND') ? toTemplate===true?6:4 : toTemplate===true?12:8, textAlign: 'end' }}>
                <PickupDetails billingControlRef={billingControlRef} selectedOrderType={selectedOrderType} disableFull={disableFull} isTemplateScreen={toTemplate}/>
            </GridItem> */}
            {
                (orderType === 'INBOUND' || orderType === 'OUTBOUND') &&
                <GridItem gridProps={{ xs: 12, md: toTemplate===true?6:4, lg: toTemplate===true?6:4 }}>
                    <Shipper billingControlRef={billingControlRef} selectedOrderType={selectedOrderType} disableFull={disableFull} isTemplateScreen={toTemplate} isEdiScreen={isEdiScreen}/>
                </GridItem>
            }
            {/* <GridItem gridProps={{ xs: 12, md: toTemplate===true?6:4, lg: toTemplate===true?6:4 }}>
                <DriverNotes billingControlRef={billingControlRef} selectedOrderType={selectedOrderType} disableFull={disableFull} isTemplateScreen={toTemplate}/>
            </GridItem> */}
        </GridContainer>}
        </>}

        </GridContainer>
    );
}